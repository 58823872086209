// convert from "00:15" to 15
const parseTimeToMinutes = (duration) => {
  let [hours, minutes] = duration.split(":").map(Number);

  if (
    isNaN(hours) ||
    hours === null ||
    hours === undefined ||
    isNaN(minutes) ||
    minutes === null ||
    minutes === undefined
  ) {
    console.error("Invalid duration format: ", duration);
    return 0;
  }

  return hours * 60 + minutes;
};

// convert from 15 to "00:15"
const parseMinutesToTime = (durationInMins) => {
  const hours = Math.floor(durationInMins / 60);
  const minutes = durationInMins % 60;

  if (
    !durationInMins ||
    durationInMins === undefined ||
    durationInMins === null ||
    isNaN(durationInMins)
  ) {
    return "00:00";
  }

  return `${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}`;
};

//converts minutes (20) into hours (0.33333)
//it will not do rounding at all
const parseMinutesToHours = (durationInMins) => {
  if (
    !durationInMins ||
    durationInMins === undefined ||
    durationInMins === null ||
    isNaN(durationInMins)
  ) {
    return 0;
  }

  return durationInMins / 60;
};

// check if string is type time (00:00)
const isTime = (time) => {
  if (typeof time === 'string') {
      return time.includes(':') && time.indexOf(':') !== 0 && time.lastIndexOf(':') !== time.length - 1;
  } else {
      return false;
  }
}

// compare two dates and checks are they same day
const isSameDay = (date1, date2) => {
  const d1 = date1 instanceof Date ? date1 : new Date(date1);
  const d2 = date2 instanceof Date ? date2 : new Date(date2);
  return d1.toDateString() === d2.toDateString();
}

const findClosestMonday = (date) => {
  const dayOfWeek = date.getDay();
  const difference = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  const closestMonday = new Date(date);
  closestMonday.setDate(date.getDate() - difference);
  return closestMonday;
};

const findClosestSunday = (date) => {
  const dayOfWeek = date.getDay();
  const difference = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
  const closestSunday = new Date(date);
  closestSunday.setDate(date.getDate() + difference);
  return closestSunday;
};

export { parseTimeToMinutes, parseMinutesToTime, parseMinutesToHours, isTime, isSameDay, findClosestMonday, findClosestSunday };

import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames"; // Import classnames
import classes from "./TimeInput.module.scss";

const TimeInput = ({ 
  onTimeChange, 
  onBlur = () => {},
  onKeyUp = () => {},
  defaultValue, 
  disabled, 
  small 
}) => {
  // defaultValue should be string "HH:MM"
  const parseTime = (timeString) => {
    const [h, m] = timeString.split(":").map(Number);
    if (Number.isNaN(h) || Number.isNaN(m) || h < 0 || h > 23 || m < 0 || m > 59) {
      return ["00", "00"];
    }
    return [h.toString().padStart(2, "0"), m.toString().padStart(2, "0")];
  };

  const [hour, setHour] = useState("00");
  const [minute, setMinute] = useState("00");
  const [lastChangeTime, setLastChangeTime] = useState(Date.now());

  const hourRef = useRef(null);
  const minuteRef = useRef(null);

  useEffect(() => {
    const [initialHour, initialMinute] = defaultValue ? parseTime(defaultValue) : ["00", "00"];
    setHour(initialHour);
    setMinute(initialMinute);
  }, [defaultValue, onTimeChange]);

  const updateTime = (newHour, newMinute) => {
    setHour(newHour);
    setMinute(newMinute);
    onTimeChange(`${newHour}:${newMinute}`);
    setLastChangeTime(Date.now());
  };

  const handleHourChange = (e) => {
    let value = e.target.value;
    if (/^\d{0,2}$/.test(value)) {
      value = value.padStart(2, "0");
      setHour(value.slice(-2));
      onTimeChange(`${value.slice(-2)}:${minute}`);
    }
  };

  const handleMinuteChange = (e) => {
    let value = e.target.value;

    if (/^\d{0,2}$/.test(value)) {
      // Make sure max value === 59
      if (value.length <= 2 && (+value < 60 || value === "")) {
        value = value.padStart(2, "0");
        setMinute(value.slice(-2));
        onTimeChange(`${hour}:${value.slice(-2)}`);
      }
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (hourRef.current === document.activeElement || minuteRef.current === document.activeElement) {
        return
      }
      onBlur(`${hour}:${minute}`);
    }, 0);
  };

  const handleKeyDown = (e, type) => {
    // Handle Left/Right
    if (e.key === "ArrowLeft") {
      if (type === "minute") {
        e.preventDefault();
        hourRef.current.focus();
      }
      return;
    }

    if (e.key === "ArrowRight") {
      if (type === "hour") {
        e.preventDefault();
        minuteRef.current.focus();
      }
      return;
    }

    // Only numbers or arrows
    if (!/^[0-9]$/.test(e.key) && e.key !== "ArrowUp" && e.key !== "ArrowDown") {
      return;
    }

    // Handle up/down keys
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      let increment = e.key === "ArrowUp" ? 1 : -1;

      if (type === "hour") {
        let newHour = (parseInt(hour, 10) + increment + 24) % 24;
        newHour = newHour.toString().padStart(2, "0");
        updateTime(newHour, minute);
      } else if (type === "minute") {
        let newMinute = (parseInt(minute, 10) + increment + 60) % 60;
        newMinute = newMinute.toString().padStart(2, "0");
        updateTime(hour, newMinute);
      }

      return;
    }

    // Write custom number
    let newHourOrMinute = e.key.padStart(2, "0");

    if (Date.now() - lastChangeTime < 500 && type === "hour") {
      newHourOrMinute = hour.charAt(1) + e.key;
    }

    if (Date.now() - lastChangeTime < 500 && type === "minute") {
      newHourOrMinute = minute.charAt(1) + e.key;
    }

    if (type === "hour") {
      updateTime(newHourOrMinute, minute);
    } else {
      if (+newHourOrMinute < 60) {
        updateTime(hour, newHourOrMinute);
      }
    }
  };

  const handleKeyUp = (e) => {
    onKeyUp(e);
  };

  return (
    <div className={classNames(classes.timeInput, { [classes.small]: small })}>
      <input
        type="text"
        ref={hourRef}
        value={hour}
        onChange={handleHourChange}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
        onKeyDown={(e) => handleKeyDown(e, "hour")}
        maxLength={2}
        name="duration_hour"
        disabled={disabled}
        className={classNames(classes.timeInputInput, { [classes.small]: small })}
      />
      <span className={classes.inputsTimeBetween}>:</span>
      <input
        type="text"
        ref={minuteRef}
        value={minute}
        onChange={handleMinuteChange}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
        onKeyDown={(e) => handleKeyDown(e, "minute")}
        maxLength={2}
        name="duration_minute"
        disabled={disabled}
        className={classNames(classes.timeInputInput, { [classes.small]: small })}
      />
    </div>
  );
};

export default TimeInput;

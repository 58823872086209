import { useState, useEffect } from "react";

export function useEntryOptions() {
  const [storedBrands, setStoredBrands] = useState([]);
  const [storedTypes, setStoredTypes] = useState([]);
  const [storedBrandsColors, setStoredBrandsColors] = useState([]);

  useEffect(() => {
    const storedBrandsLocal = JSON.parse(localStorage.getItem("hdd_options_brands"));
    const storedTypesLocal = JSON.parse(localStorage.getItem("hdd_options_types"));
    const storedBrandsColors = JSON.parse(localStorage.getItem('hdd_options_brands_colors'));

    if (storedBrandsLocal !== undefined && storedBrandsLocal && storedBrandsLocal.length) {
      setStoredBrands(storedBrandsLocal);
    }

    if (storedTypesLocal !== undefined && storedTypesLocal && storedTypesLocal.length) {
      setStoredTypes(storedTypesLocal);
    }

    if (storedBrandsColors !== undefined && storedBrandsColors && storedBrandsColors.length) {
      setStoredBrandsColors(storedBrandsColors);
    }
  }, []);

  const storeBrands = (brands) => {
    if (brands !== undefined && brands.length) {
      localStorage.setItem("hdd_options_brands", JSON.stringify(brands));
    }
  }
  
  const storeBrandsColors = (brandsColors) => {
    if (brandsColors !== undefined && brandsColors.length) {
      localStorage.setItem("hdd_options_brands_colors", JSON.stringify(brandsColors));
    }
  }
  
  const storeTypes = (types) => {
    if (types !== undefined && types.length) {
      localStorage.setItem("hdd_options_types", JSON.stringify(types));
    }
  }

  return { storedBrands, storedTypes, storedBrandsColors, storeBrands, storeBrandsColors, storeTypes };
}

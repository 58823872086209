import { useState, useEffect, useRef } from "react";

export const useEntries = (
  initialUserId,
  initialDateFrom,
  initialDateTo
) => {

  const [isInitiallyLoadingEntries, setIsInitiallyLoadingEntries] = useState(true);
  const [isLoadingEntries, setIsLoadingEntries] = useState(false);
  const [isReorderingEntries, setIsReorderingEntries] = useState(false);
  const [entries, setEntries] = useState([]);
  const [lastEntriesCache, setLastEntriesCache] = useState([]);
  const abortControllerRef = useRef();
  const abortControllerReorderRef = useRef();

  const fetchEntries = async () => {
    if (isLoadingEntries && abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    setIsLoadingEntries((val) => true);

    const dateFrom = initialDateFrom && initialDateFrom instanceof Date
    ? initialDateFrom
    : new Date();

    const dateTo = initialDateTo && initialDateTo instanceof Date
    ? initialDateTo
    : dateFrom;

    try {
      const controller = new AbortController();
      abortControllerRef.current = controller;

      const entriesReq = await fetch("/api/hdd/time-entries/get", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: initialUserId,
          dateFrom,
          dateTo,
        }),
        signal: controller.signal,
      });

      const entriesData = await entriesReq.json();
      const { data } = entriesData;

      setLastEntriesCache(data);
      setEntries(data);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request aborted:", error.message);
      } else {
        console.error("Error fetching users:", error);
      }
    } finally {
      setIsInitiallyLoadingEntries(false);

      setTimeout(() => {
        setIsLoadingEntries((val) => false);
      }, 0);
    }
  };

  const reorderEntries = async (timeEntriesArray) => {
    if (isReorderingEntries && abortControllerReorderRef.current) {
      abortControllerReorderRef.current.abort();
      setIsReorderingEntries((val) => false);
    }
    
    setIsReorderingEntries((val) => true);

    try {
      const controller = new AbortController();
      abortControllerReorderRef.current = controller;

      //prepare happy path response to mock current state
      const predictedEntries = JSON.parse(JSON.stringify(lastEntriesCache));
      const dateFrom = new Date(predictedEntries.dateFrom);
      dateFrom.setHours(0, 0, 0, 0);

      for (const dayI of predictedEntries.days.keys()) {
        const currentDate = new Date(dateFrom);
        currentDate.setHours(0, 0, 0, 0);
        currentDate.setDate(dateFrom.getDate() + dayI);

        for (const entryI of predictedEntries.days[dayI].entries.keys()) {
          const entry = predictedEntries.days[dayI].entries[entryI];
          const updatedEntry = timeEntriesArray.find((e) => e.id === entry.id);
          if (updatedEntry) {
            predictedEntries.days[dayI].entries[entryI].entry_order = updatedEntry.entry_order;

            const updatedEntryDate = new Date(updatedEntry.entry_date);
            updatedEntryDate.setHours(0, 0, 0, 0);
            if (currentDate.getDate() !== new Date(updatedEntry.entry_date).getDate()) {
              predictedEntries.days[dayI].entries.splice(entryI, 1);
              //this count must be so complicated, using .getDate will not work on the different months
              const newDayI = Math.floor((updatedEntryDate - dateFrom) / (1000 * 60 * 60 * 24));
              predictedEntries.days[newDayI].entries.push(updatedEntry);
            }
          }
        }
      }

      for (const dayI of predictedEntries.days.keys()) {
        predictedEntries.days[dayI].entries.sort((a, b) => a.entry_order - b.entry_order);
      }

      setEntries(predictedEntries);

      const entriesReq = await fetch("/api/hdd/time-entries/reorder", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(timeEntriesArray),
        signal: controller.signal,
      });

      const entriesData = await entriesReq.json();
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request aborted:", error.message);
      } else {
        console.error("Error fetching users:", error);
      }

      setEntries(lastEntriesCache);
    } finally {
      setTimeout(() => {
        setIsReorderingEntries((val) => false);
      }, 0);
    }
  };

  useEffect(() => {
    fetchEntries();
  }, [initialUserId, initialDateFrom, initialDateTo]);

  return {
    isLoadingEntries,
    isInitiallyLoadingEntries,
    isReorderingEntries,
    entries,
    fetchEntries,
    reorderEntries
  };
};
import { useEffect, useState } from "react";
import classes from "./DingDongPageComponent.module.scss";
import CalendarWeek from "./CalendarWeek";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../store/hdd/hdd-store";
import UserSelect from "./atoms/UserSelect";
import WelcomeScreen from "./WelcomeScreen";
import { useEntryOptions } from "./table-parts/hooks/useEntryOptions";
import TopBar from "@/components/hdd/TopBar";

export default function DingDongPageComponent(props) {
  const entryOptions = useEntryOptions();
  const { storeBrands, storeTypes, storeBrandsColors } = entryOptions;
  const { data: session, status } = useSession();
  const [userJustSigned, setUserJustSigned] = useState(false);

  const router = useRouter();
  const { pathname, query } = router;
  const dispatch = useDispatch();
  const storeUser = useSelector((state) => state.hdd.user);

  useEffect(() => {
    console.log(query);
    if (query?.signed && query?.signed !== undefined && query?.signed === "true") {
      setUserJustSigned(true);
    }
  }, [query.signed]);

  const getTasksAndBrands = async () => {
    try {
      const request = await fetch("/api/hdd/asana/brands-types");
      const result = await request.json();

      if (result?.brands !== undefined && result.brands.length) {
        storeBrands(result.brands);
      }

      if (result?.types !== undefined && result.types.length) {
        storeTypes(result.types);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBrandsColors = async () => {
    try {
      const request = await fetch("/api/hdd/asana/brands/colors");
      const result = await request.json();

      if (result && result?.length) {
        storeBrandsColors(result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBrandsColors();
    getTasksAndBrands();
  }, []);

  // set storeUser as current user on app load
  useEffect(() => {
    if (status === "authenticated" && !storeUser.id) {
      dispatch(setUser({ id: session.user.id }));
    }
  }, [status, session, dispatch, storeUser]);

  return (
    <>
      <TopBar />

      {userJustSigned ? (
        <WelcomeScreen />
      ) : (
        <div className={classes.container}>
          {session && session.user.role !== "USER" && <UserSelect />}
          <CalendarWeek userId={storeUser.id} />
        </div>
      )}
    </>
  );
}

import { useState, useRef } from "react";
import classes from "./SingleEntry.module.scss";
import BrandBadge from "../atoms/BrandBadge";
import TypeIcon from "../atoms/TypeIcon";
import { parseMinutesToTime, parseTimeToMinutes } from "../helpers/time";
import Image from "next/legacy/image";
import icoTrash from "../assets/images/ico-trash.svg";
import icoPencil from "../assets/images/ico-pencil.svg";
import icoClone from "../assets/images/ico-clone.svg";
import { useDispatch } from "react-redux";
import { setPopupState, setEditingEntry } from "../../../store/hdd/hdd-store";
import Popup from "../../elements/Popup";
import DeleteConfirm from "./DeleteConfirm";
import { useSpecificEntry } from "../hooks/useSpecificEntry";
import { useEntry } from "../hooks/useEntry";
import TimeInput from "../atoms/TimeInput";

export default function SingleEntry(props) {
  const dispatch = useDispatch();
  const { entry, index } = props;
  const popupRef = useRef();
  const initDuration = parseMinutesToTime(entry.entry_duration);
  const [duration, setDuration] = useState(initDuration);
  const [confirmingRemove, setConfirmingDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { updateSingleEntry, isUpdatingEntry } = useSpecificEntry();
  const { addEntry, isAddingEntry } = useEntry();

  const onClosePopup = () => {
    setConfirmingDelete(false);
  };

  const deleteEntry = async () => {
    if (isDeleting) {
      return;
    }

    setIsDeleting(true);

    try {
      const usersReq = await fetch("/api/hdd/time-entry/delete", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: entry.id }),
      });
      // const usersData = await usersReq.json();

      if (!usersReq.ok) {
        console.error("Error removinf entry");
      }
    } catch (error) {
      console.error(error);
    } finally {
      props.onSingleEntryChange();
      setIsDeleting(false);
      onClosePopup();
    }
  };

  const onEditClick = async () => {
    dispatch(setPopupState("edit"));
    dispatch(setEditingEntry(entry));
  };

  const onCloneClick = async () => {
    if (isAddingEntry) {
      return;
    }

    const newEntry = { ...props.entry };
    delete newEntry.id;
    const newEntryWithPropperKeys = {};

    for (let key in newEntry) {
      if (key.startsWith("entry")) {
        const newKey = key.replace(/^entry_/, ""); // Removes 'entry' prefix
        newEntryWithPropperKeys[newKey] = newEntry[key];
      } else {
        newEntryWithPropperKeys[key] = newEntry[key];
      }
    }

    const cloneEntry = await addEntry(newEntryWithPropperKeys);

    if (cloneEntry) {
      await props.onSingleEntryChange();
    } else {
      console.error("couldn't clone entry");
    }
  };

  // const OnDurationBlur = async (event) => {
  //   const durationInMins = parseTimeToMinutes(event.target.value);

  //   if (props.entry.entry_duration !== durationInMins) {
  //     const newEntry = { ...entry };
  //     newEntry.duration = durationInMins;
  //     await updateSingleEntry(newEntry);
  //     await props.onSingleEntryChange();
  //   }
  // };

  // const onDurationKeyup = async (event) => {
  //   const durationInMins = parseTimeToMinutes(event.target.value);

  //   if (event.key === "Enter" && props.entry.entry_duration !== durationInMins) {
  //     const newEntry = { ...entry };
  //     newEntry.duration = durationInMins;
  //     await updateSingleEntry(newEntry);
  //     await props.onSingleEntryChange();
  //   }
  // };

  // const onDurationChange = (event) => {
  //   setDuration(event.target.value);
  // };

  const onDragStart = (event) => {
    const obj = { elementId: event.currentTarget.id, entryId: entry.id, entryDateFrom: entry.entry_date, order: entry.entry_order };
    event.dataTransfer.setData("text/plain", JSON.stringify(obj));
    // event.currentTarget.style.backgroundColor = "#f0f0ff";
    event.currentTarget.classList.add(`${classes.isDragging}`);
  };

  const onDragEnd = (event) => {
    event.currentTarget.classList.remove(`${classes.isDragging}`);
  };

  const onDragOverOrder = (event) => {
    const { target } = event;
    const newOrder = target.dataset.newOrder;
    target.classList.add(`${classes.onDragOverOrder}`);
  };

  const onDragLeaveOrder = (event) => {
    const { target } = event;
    target.classList.remove(`${classes.onDragOverOrder}`);
  };

  const handleTimeChange = (time) => {
    setDuration(time);
  };

  const onNewDurationBlur = async (event) => {
    if (initDuration === duration) {
      return;
    }

    const durationInMins = parseTimeToMinutes(duration);
    const newEntry = { ...entry };
    newEntry.duration = durationInMins;
    await updateSingleEntry(newEntry);
    await props.onSingleEntryChange();
  }

  const onNewDurationKeyUp = async (event) => {
    if (event.key === "Enter") {
      if (initDuration === duration) {
        return;
      }

      const durationInMins = parseTimeToMinutes(duration);
      const newEntry = { ...entry };
      newEntry.duration = durationInMins;
      await updateSingleEntry(newEntry);
      await props.onSingleEntryChange();
    }
  };

  return (
    <>
      <Popup ref={popupRef} visible={confirmingRemove} theme="ding-dong" onClose={onClosePopup} key={confirmingRemove} lockBody={false}>
        <DeleteConfirm deleteEntry={deleteEntry} onClosePopup={onClosePopup} isDeleting={isDeleting} />
      </Popup>
      <div className={classes.entryWrapper}>
        <div className={classes.entry} onDragStart={onDragStart} onDragEnd={onDragEnd} id={`entry-${entry.id}`}>
          <div className={classes.entryTop}>
            <div className={classes.entryTopIcons}>
              {entry.entry_brand ? (
                <>
                  <TypeIcon type={entry.entry_type} />
                  <BrandBadge brand={entry.entry_brand} />
                </>
              ) : null}
            </div>
            <button
              onClick={() => {
                setConfirmingDelete(true);
              }}
              className={classes.btnRemove}
              title="Remove"
            >
              <Image src={icoTrash} alt="" layout="fixed" width={24} height={24} />
            </button>
          </div>
          <div>
            {entry.entry_url ? (
              <a href={entry.entry_url} className={classes.entryUrl} target="_blank" rel="noreferrer">
                <p className={classes.entryTitle}>{entry.entry_title}</p>
              </a>
            ) : (
              <div>
                <p className={classes.entryTitle}>{entry.entry_title}</p>
              </div>
            )}
          </div>
          <div className={classes.entryBottom}>
            {/* <input
              className={cn(classes.input, classes.inputTime)}
              type="time"
              value={duration}
              onChange={onDurationChange}
              onBlur={OnDurationBlur}
              onKeyUp={onDurationKeyup}
              disabled={isUpdatingEntry}
            /> */}
            <TimeInput onTimeChange={handleTimeChange} defaultValue={duration} disabled={isUpdatingEntry} onBlur={onNewDurationBlur} onKeyUp={onNewDurationKeyUp} small />
            <div className={classes.entryBottomRight}>
              <button className={classes.input} onClick={onEditClick} title="Edit">
                <Image src={icoPencil} alt="" layout="fixed" width={24} height={24} />
                <span className={classes.desktopOnlyText}>Edit</span>
              </button>
              <button className={classes.input} onClick={onCloneClick} title="Clone" disabled={isAddingEntry}>
                <Image src={icoClone} alt="" layout="fixed" width={24} height={24} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import slugify from "slugify";
import { useEntryOptions } from "../table-parts/hooks/useEntryOptions";
import classes from "./TypeIcon.module.scss";
import Image from "next/image";

export default function TypeIcon(props) {
  const entryOptions = useEntryOptions();
  const { storedTypes } = entryOptions;
  const [typesIconsMap, setTypesIconsMap] = useState({});

  useEffect(() => {
    if (!storedTypes) {
      return;
    }

    const map = {
      design: "/images/type-icons/Design.svg",
      programming: "/images/type-icons/Programming.svg",
      content: "/images/type-icons/Content.svg",
      operations: "/images/type-icons/Operations.svg",
      vacation: "/images/type-icons/Vacation.svg",
      sickness: "/images/type-icons/Sickness.svg",
      businesstrip: "/images/type-icons/BusinessTrip.svg",
      trainingconference: "/images/type-icons/Training.svg",
      ooo: "/images/type-icons/OOO.svg",
    };

    const filteredMap = {};
    storedTypes.forEach((type) => {
      if (map[makeSlug(type)]) {
        filteredMap[makeSlug(type)] = map[makeSlug(type)];
      }
    });
    setTypesIconsMap(filteredMap);
  }, [storedTypes]);

  const makeSlug = (text) => {
    return slugify(text, {
      remove: /[*+~.,()'"!:@ /]/g,
      lower: true,
    });
  };

  const showIcon = () => {
    const type = props?.type || "operations";
    const map = typesIconsMap[makeSlug(type)] || null;

    if (map) {
      return <Image src={map} alt={type} width={24} height={24} />;
    }

    return null;
  };

  return <div className={classes.typeIcon}>{showIcon()}</div>;
}

import classes from "./DeleteConfirm.module.scss";
import cn from "classnames";

export default function DeleteConfirm(props) {
  const onClickYes = () => {
    console.log('yes')
    props.deleteEntry();
  }
  
  const onClickNo = () => {
    props.onClosePopup();
  }

  return (
    <div className={classes.confirmWrapper}>
      <p className={classes.confirmTitle}>
          Are you sure you want to delete this task?
      </p>
      <div className={classes.confirmButtons}>
        <button className={cn(classes.confirmButton, classes.confirmButtonYes)} onClick={onClickYes} disabled={props.isDeleting}>Yes</button>
        <button className={cn(classes.confirmButton, classes.confirmButtonNo)} onClick={onClickNo} disabled={props.isDeleting}>No</button>
      </div>
    </div>
  );
}

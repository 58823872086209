import { useEffect, useState, useRef } from "react";
import classes from "./FormEntry.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { setEditingEntry } from "../../../store/hdd/hdd-store";
import { useEntry } from "../hooks/useEntry";
import { useSpecificEntry } from "../hooks/useSpecificEntry";
import { parseMinutesToTime, parseTimeToMinutes } from "../helpers/time";
import Dropdown from "./inputs/dropdown";
import { defaultEmptyBrand } from "../constants/brands";
import { useEntryOptions } from "../table-parts/hooks/useEntryOptions";
import { isBrandDisabled } from "../helpers/form";
import TimeInput from "../atoms/TimeInput";

function isEmptyObj(obj) {
  return Object.keys(obj).length === 0;
}

export default function FormEntry(props) {
  const entryOptions = useEntryOptions();
  const { storedBrands, storedTypes } = entryOptions;
  const DEFAULT_DURATION_HOURS = "00";
  const DEFAULT_DURATION_MINUTES = "00";
  const DEFAULT_DURATION = `${DEFAULT_DURATION_HOURS}:${DEFAULT_DURATION_MINUTES}`;

  const { addEntry, isAddingEntry } = useEntry();
  const { updateSingleEntry, isUpdatingEntry } = useSpecificEntry();
  const storeUser = useSelector((state) => state.hdd.user);
  const titleRef = useRef();
  const brandRef = useRef();

  const dispatch = useDispatch();
  const { popupState, addingDate, editingEntry, cloningEntry } = useSelector((state) => state.hdd);
  const [selectedDate, setSelectedDate] = useState();

  const checkForDefaultValue = (nameInDb) => {
    let entryObject = {};

    if (popupState === "edit") {
      entryObject = editingEntry;
    }

    if (popupState === "clone") {
      entryObject = cloningEntry;
    }

    if (!entryObject || entryObject === undefined || isEmptyObj(entryObject)) {
      if (nameInDb === "entry_duration") {
        return DEFAULT_DURATION;
      } else {
        return ""
      }
    }

    if (nameInDb === "entry_duration") {
      if (popupState === "add") {
        return DEFAULT_DURATION;
      } else {
        const duration = entryObject?.[nameInDb] ?? DEFAULT_DURATION; 
        return parseMinutesToTime(duration);
      }
    }

    return entryObject?.[nameInDb] ?? "";
  }

  const currentDuration = useRef(checkForDefaultValue("entry_duration"));

  const formatDateForInput = (date) => {
    const dateObj = date ? new Date(date) : new Date();
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const getDefaultDate = () => {
    if (popupState === "edit") {
      const editDate = checkForDefaultValue("entry_date") ?? "";
      return formatDateForInput(editDate);
    }
    
    if (popupState === "clone") {
      const cloneDate = checkForDefaultValue("entry_date") ?? "";
      return formatDateForInput(cloneDate);
    }

    if (popupState === "add") {
      const addDate = formatDateForInput(addingDate);
      return addDate;
    }

    return formatDateForInput();
  }

  useEffect(() => {
    setSelectedDate(getDefaultDate());
  }, [addingDate, editingEntry, cloningEntry])  

  const handleDateChange = (e) => {
    e.preventDefault();
    setSelectedDate(e.target.value);
  };

  const onRequestSuccess = (e) => {
    console.log('success, refresh');
    props.fetchEntries();

    dispatch(setEditingEntry(null));
  }
  
  const onRequestError = (e) => {
    console.log('error');
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    let formData = {};

    for (let [key, value] of form.entries()) {
      formData[key] = value;
    }

    // formData['userId'] = session.data.user.id;
    formData['userId'] = storeUser.id;

    formData.duration = parseTimeToMinutes(currentDuration.current); // override old input's value with new formatted value from custom input

    console.log(formData);

    switch (popupState) {
      case "add":
        const entry = await addEntry(formData);

        if (entry) {
          onRequestSuccess();
        } else {
          onRequestError();
        }

        break;
      
      case "clone":
        const cloneEntry = await addEntry(formData);

        if (cloneEntry) {
          onRequestSuccess();
        } else {
          onRequestError();
        }

        break;

      case "edit":
        formData.id = editingEntry?.id ?? '';

        console.log('editing entry:');
        const edit = await updateSingleEntry(formData);

        if (edit) {
          onRequestSuccess();
        } else {
          onRequestError();
        }

        break;

      default:
        break;
    }
  };

  useEffect(() => {
    titleRef.current.focus();
  }, []);

  
  const  handleTypeChange = (newVal) => {
    const selectedType = newVal.toLowerCase();
    
    if (selectedType === "programming" || selectedType === "design") {
      console.log('any do nothing')
    }
    
    if (selectedType === "operations") {
      console.log('set brand none');
      brandRef.current.setValue(defaultEmptyBrand);
    }
    
    if (isBrandDisabled(selectedType)) {
      console.log('force set brand none')
      brandRef.current.setValue(defaultEmptyBrand);
      // brandRef.current.disable();
      brandRef.current.pseudoDisable();
    } else {
      // brandRef.current.enable();
      brandRef.current.pseudoEnable();
    }
  }

  const handleTimeChange = (time) => {
    console.log("Selected Time:", time);
    currentDuration.current = time;
  };

  return (
    <>
      {popupState === "add" && <p className={classes.title}>New Time Entry</p>}
      {popupState === "edit" && <p className={classes.title}>Edit Task</p>}
      {popupState === "clone" && <p className={classes.title}>Duplicate Task</p>}

      <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
        {popupState === "edit" && editingEntry?.id !== undefined && editingEntry.id && <input placeholder="id" name="id" defaultValue={checkForDefaultValue("id")} type="hidden" />}

        <input ref={titleRef} className={classes.input} type="text" defaultValue={checkForDefaultValue("entry_title")} placeholder="title" name="title" required />
        <input className={classes.input} type="text" defaultValue={checkForDefaultValue("entry_description")} placeholder="description" name="description" />
        <Dropdown options={storedTypes} name="type" defaultValue={checkForDefaultValue("entry_type")} placeholder="Task type" onChange={handleTypeChange} />
        <Dropdown options={storedBrands} name="brand" defaultValue={checkForDefaultValue("entry_brand")} placeholder="Brand" ref={brandRef} />

        <input type="hidden" defaultValue={checkForDefaultValue("entry_url")} placeholder="url" name="url" />

        {/* <input className={classes.input} type="time" defaultValue={checkForDefaultValue("entry_duration")} placeholder="00:00" name="duration" required /> */}

        <TimeInput onTimeChange={handleTimeChange} defaultValue={checkForDefaultValue("entry_duration")} />
        
        <input
          className={classes.input}
          type="date"
          placeholder="date"
          name="date"
          value={selectedDate}
          onChange={handleDateChange}
          required
        />
        <button className={classes.button} type="submit" disabled={isAddingEntry || isUpdatingEntry}>
          Submit
        </button>
      </form>
    </>
  );
}
import { useState, useRef } from "react";

export const useSpecificEntry = () => {
  const [isUpdatingEntry, setIsisUpdatingEntry] = useState(false);
  const abortControllerRef = useRef();

  const updateSingleEntry = async (entryData) => {    
    if (isUpdatingEntry && abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setIsisUpdatingEntry(true);

    try {
      abortControllerRef.current = new AbortController();

      const req = await fetch("/api/hdd/time-entry/update", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({...entryData}),
      });
      
      const data = await req.json();
      return req.ok;

    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request aborted:", error.message);
      } else {
        console.error("Error fetching users:", error);
      }

      return false;
    } finally {
      setIsisUpdatingEntry(false);
    }
  };

  return {isUpdatingEntry, setIsisUpdatingEntry, updateSingleEntry};
};

import { useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import SlimSelect from "slim-select";

const Dropdown = (props, ref) => {
  const inpuRef = useRef();
  const slim = useRef();

  useImperativeHandle(ref, () => ({
    setValue(newValue) {
      slim.current.setSelected(newValue);
    },
    disable() {
      slim.current.disable();
    },
    enable() {
      slim.current.enable();
    },
    pseudoDisable() {
      //disable only visually but still return value
      inpuRef.current.classList.add('pseudo-disabled');
    },
    pseudoEnable() {
      //disable only visually but still return value
      inpuRef.current.classList.remove('pseudo-disabled');
    }
  }));

  useEffect(() => {
    if (props.options && props.options.length > 0) {
      slim.current = new SlimSelect({
        select: inpuRef.current,
        settings: {
          placeholderText: props?.placeholder ?? "",
        },
        events: {
          afterChange: (newVal) => {
            if (props.onChange) {
              props.onChange(inpuRef.current.value);
            }
          },
        },
      });
    }

    return () => {
      slim?.current?.destroy();
    };
  }, [props]);

  return (
    <>
      {props.options && props.options.length > 0 && (
        <select name={props.name} id={props.id} ref={inpuRef} defaultValue={props.defaultValue} disabled={props?.disabled} pseudoDisabled={props?.pseudoDisabled} pseudoDisabledValue={props?.pseudoDisabledValue}>
          {props.options.map((item, index) => (
            <option key={item}>{item}</option>
          ))}
        </select>
      )}
    </>
  );
};

export default forwardRef(Dropdown);

const disabledBrandTypesList = [
  "vacation", 
  "sickness", 
  "conference", 
  "training/conference", 
  "ooo", 
  "business trip"
];

const isBrandDisabled = (type) => {
  const typeInLowercase = type?.toLowerCase() ?? "";

    const disabledBrandTypesList = [
      "vacation", 
      "sickness", 
      "conference", 
      "training/conference", 
      "ooo", 
      "business trip"
    ];
  
    if (disabledBrandTypesList.includes(typeInLowercase)) {
      return true
    } else {
      return false;
    }
};


export { isBrandDisabled, disabledBrandTypesList };

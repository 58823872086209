import { useEffect, useState, useRef } from "react";
import classes from "./DingDongPageComponent.module.scss";
import { useEntries } from "./hooks/useEntries";
import WeekTable from "./table-parts/WeekTable";
import WeekTableLoader from "./table-parts/WeekTableLoader";
import Popup from "../elements/Popup";
import WeekTotal from "./atoms/WeekTotal";
import { findClosestMonday, findClosestSunday } from "./helpers/time";
import FormEntry from "./table-parts/FormEntry";
import Image from "next/legacy/image";
// import icoCalendar from "./assets/images/ico-calendar.svg";
import icoArrowL from "./assets/images/ico-arrow-l.svg";
import icoArrowR from "./assets/images/ico-arrow-r.svg";
import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { setPopupState } from "../../store/hdd/hdd-store";
import ReportsPanel from "./atoms/ReportsPanel";
import { useSession } from "next-auth/react";
import Spinner from "@/components/elements/Spinner";

export default function CalendarWeek(props) {
  const dispatch = useDispatch();
  const { popupState } = useSelector((state) => state.hdd);
  const { userId } = props;
  const [dateFrom, setDateFrom] = useState(findClosestMonday(new Date()));
  const [dateTo, setDateTo] = useState(findClosestSunday(new Date()));
  const { setUserId, isLoadingEntries, isInitiallyLoadingEntries, isReorderingEntries, entries, fetchEntries, reorderEntries } = useEntries(userId, dateFrom, dateTo);
  const popupRef = useRef();
  const { data: session } = useSession();

  useEffect(() => {
    if (isReorderingEntries) {
      return;
    }

    fetchEntries();
  }, [isReorderingEntries]);

  const handlePrevClick = () => {
    const newDateFrom = new Date(dateFrom);
    newDateFrom.setDate(newDateFrom.getDate() - 7);
    const newDateTo = findClosestSunday(newDateFrom);
    setDateFrom(newDateFrom);
    setDateTo(newDateTo);
  };

  const handleNextClick = () => {
    const newDateFrom = new Date(dateFrom);
    newDateFrom.setDate(newDateFrom.getDate() + 7);
    const newDateTo = findClosestSunday(newDateFrom);
    setDateFrom(newDateFrom);
    setDateTo(newDateTo);
  };

  const formatDate = (date) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "2-digit",
      year: "numeric",
    };

    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    const dateParts = formattedDate.split(" ");
    dateParts[0] = dateParts[0].slice(0, -1);
    dateParts[0] = `<span>${dateParts[0]},</span>`;
    const finalFormattedDate = dateParts.join(" ");
    return finalFormattedDate;
  };

  const onClosePopup = () => {
    dispatch(setPopupState(""));
  };

  useEffect(() => {
    if (popupState === "add" || popupState === "edit" || popupState === "clone") {
      popupRef.current.show();
    }
  }, [popupState]);

  return (
    <>
      <Popup
        ref={popupRef}
        visible={popupState === "add" || popupState === "edit" || popupState === "clone"}
        theme="ding-dong"
        onClose={onClosePopup}
        key={popupState}
        lockBody={false}
      >
        <FormEntry fetchEntries={fetchEntries} />
      </Popup>

      <div className={classes.topPart}>
        {dateFrom && dateFrom !== null ? (
          <div className={classes.dateChanger}>
            {/* <button className={classes.dateChangerBtn}>
              <Image
                src={icoCalendar}
                alt=""
                layout="fixed"
                width={24}
                height={24}
              />
            </button> */}
            <button onClick={handlePrevClick} className={cn(classes.dateChangerBtn, classes.dateChangerBtnIcoLeft)}>
              <Image src={icoArrowL} alt="" layout="fixed" width={48} height={24} />
              <span>Previous Week</span>
            </button>
            <div>
              <p className={classes.dateChangerTxt}>
                <span className={classes.dateChangerDate} dangerouslySetInnerHTML={{ __html: formatDate(dateFrom) }}></span> —{" "}
                <span className={classes.dateChangerDate} dangerouslySetInnerHTML={{ __html: formatDate(dateTo) }}></span>
              </p>
            </div>
            <button onClick={handleNextClick} className={cn(classes.dateChangerBtn, classes.dateChangerBtnIcoRight)}>
              <span>Next Week</span>

              <Image src={icoArrowR} alt="" layout="fixed" width={48} height={24} />
            </button>
          </div>
        ) : null}

        <div>{<WeekTotal entries={entries} key={entries} />}</div>
        {/*{userId === process.env.NEXT_PUBLIC_SA_USER_ID ? <ReportsPanel dateFrom={dateFrom} dateTo={dateTo} /> : ''}*/}
        {/* { session.user.role === "BOSS" && (<ReportsPanel dateFrom={dateFrom} dateTo={dateTo} />)} */}
      </div>

      <div
        className={cn(classes.weekTableWrapper, {
          [classes.weekTableWrapperLoading]: isInitiallyLoadingEntries,
        })}
      >
        {isInitiallyLoadingEntries ? <WeekTableLoader /> : null}

        {!isInitiallyLoadingEntries && entries && entries.days && entries?.days?.length ? (
          <div className={classes.weekTableOuter}>
            {(isLoadingEntries || isReorderingEntries) ? <Spinner className={classes.weekTableOuterSpinner} /> : null}
            <WeekTable entries={entries} key={entries.days} fetchEntries={fetchEntries} reorderEntries={reorderEntries} />
          </div>
        ) : (
          <p></p>
        )}
      </div>

      <ReportsPanel dateFrom={dateFrom} dateTo={dateTo} />
    </>
  );
}

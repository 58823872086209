import Image from "next/image";

import {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import classes from "./Popup.module.scss";
import cn from "classnames";
import closeIcon from "../../public/images/popup-close.svg";
import { createPortal } from "react-dom";

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

// visible - state from redux
// isVisible - popup internal state

const Popup = (
  {
    visible,
    className: extraClasses,
    theme,
    children,
    onOpen,
    onClose,
    video,
    vertical,
    id,
    lockBody = true
  },
  ref
) => {
  const [isVisible, setIsVisible] = useState(visible);

  const portalRef = useRef();
  const [mounted, setMounted] = useState(false);

  const closePopup = () => {
    if (onClose) onClose();
    setIsVisible(false);
  };

  const showPopup = () => {
    console.log('show')
    setIsVisible(true);
    if (onOpen) onOpen();
  };

  const onClickOverlay = (e) => {
    if (e.target.dataset.overlay == "true") {
      closePopup();
    }
  }

  useImperativeHandle(ref, () => ({
    show: () => showPopup(),
    close: () => closePopup(),
  }));

  useEffect(() => {
    portalRef.current = document.querySelector("#portal");
    setMounted(true);
  }, []);

  useEffect(() => {
    const onKeyUp = (e) => {
      if (e.key === "Escape") {
        closePopup();
      }
    };

    if (isVisible && lockBody) {
      document.body.classList.add("popupLock");
    } else {
      document.body.classList.remove("popupLock");
    }

    if (isVisible) {
      document.body.addEventListener("keyup", onKeyUp);
    } else {
      document.body.removeEventListener("keyup", onKeyUp);
    }
    
    return () => {
      clearAllBodyScrollLocks();
      document.body.removeEventListener("keyup", onKeyUp);
    };
  }, [isVisible, lockBody]);

  return mounted && portalRef.current
    ? createPortal(
        <div
          ref={ref}
          id={id}
          onClick={onClickOverlay}
          className={cn(classes.popupOverlay, {
            [classes.visible]: isVisible,
            [classes.video]: video,
            [classes.dingDongOverlay]: theme === "ding-dong",
          })}
          data-overlay
        >
          <div
            className={cn(classes.popupBody, {
              [classes.video]: video,
              [classes.vertical]: vertical,
              [classes.horizontal]: !vertical,
            })}
          >
            <button
              className={classes.popupClose}
              onClick={(e) => {
                e.preventDefault();
                closePopup();
              }}
            >
              <Image src={closeIcon} alt="Close" />
            </button>
            <div className={cn(classes.popupContent, extraClasses)}>
              {children}
            </div>
          </div>
        </div>,
        portalRef.current
      )
    : null;
};

export default forwardRef(Popup);

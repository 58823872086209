import { useState, useRef } from "react";

export const useEntry = () => {
  const [isAddingEntry, setIsisAddingEntry] = useState(false);
  const abortControllerRef = useRef();

  const addEntry = async (entryData) => {
    if (isAddingEntry && abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    setIsisAddingEntry(true);

    try {
      abortControllerRef.current = new AbortController();

      const req = await fetch("/api/hdd/time-entry/add", {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          // "Authorization": `Bearer ${session.user.api_key}`, // only needed when no access to userId
        }),
        body: JSON.stringify({...entryData}),
      });

      const data = await req.json();
      return req.ok;

    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request aborted:", error.message);
      } else {
        console.error("Error fetching users:", error);
      }
      return false;
    } finally {
      setIsisAddingEntry(false);
    }
  };

  return {isAddingEntry, setIsisAddingEntry, addEntry};
};
import DingDongPageComponent from "@/components/hdd/DingDongPageComponent";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

export default function HugeDingDongPage({ fallback }) {
  const { data: session, status } = useSession();
    const router = useRouter();

    useEffect(() => {
        if (status === "unauthenticated") {
            router.push("/login");
        }
    }, [status, router]);

    if (status === "authenticated") {
      return <DingDongPageComponent />;
    }
}

import { useState, useEffect } from "react";
import { parseMinutesToTime } from "../helpers/time";
import classes from "./WeekTotal.module.scss";

export default function WeekTotal(props) {
  const getTotalHours = (days) => {
    let totalHours = 0;

    if (days !== undefined && days !== null && days.length) {
      days.forEach((day) => {
        day.entries.forEach((entry) => {
          totalHours += entry.entry_duration;
        });
      });
    }
  
    return totalHours;
  };

  const [totalTime, setTotalTime] = useState(getTotalHours(props?.entries?.days ?? null));

  useEffect(() => {
    setTotalTime(getTotalHours(props?.entries?.days ?? null));
  }, [props.entries.days])
  

  return (
    <div className={classes.weekTotal}>Week Total: {parseMinutesToTime(totalTime)}</div>
  );
}

import classes from "./WeekTable.module.scss";
import cn from "classnames";

export default function WeekTableLoader(props) {
  const weeKdays = [1,2,3,4,5,6,7];
  const dayItems = [1,2,3];

  return (
    <div className={cn(classes.weekTable, classes.weekTableLoading)}>
      <div className={classes.weekTableBody}>
        {weeKdays.map((item, index) => (
          <div
            key={item}
            className={cn(classes.weekTableBodyCol)}
          >
            <div key={item.date} className={cn(classes.weekTableHead)}>
              <div className={classes.dayName}></div>
              <div className={cn(classes.weekTableHeadAddWrapper)}>
                <button className={cn(classes.addBtn, classes.addBtnMockup)}>
                  <em className={classes.loadingMockupText}>▆▆▆</em>
                </button>
              </div>
            </div>
            <div className={classes.weekTableContent}>
              {dayItems.map((entry, index) => (
                <div className={classes.weekTableFakeItem} key={index}></div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
